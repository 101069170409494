import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseUrl } from "../utils/ImiUtilData";

const userApi = createApi({
  reducerPath: "userApi",
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl,
  }),
  endpoints: (builder) => ({
    getUserTokenFB: builder.mutation({
      query: (fbToken) => ({
        url: `/user/signup/fb?token=${fbToken}`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      }),
      transformResponse: (response) => response?.data,
    }),
    getUserTokenGoogle: builder.mutation({
      query: (googleToken) => ({
        url: `/user/signup/google_sign_in?token=${googleToken}`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      }),
      transformResponse: (response) => response?.data,
    }),
    getUserProfile: builder.mutation({
      query: (userToken) => ({
        url: `/user/user`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }),
      transformResponse: (response) => response?.data,
    }),
  }),
});

export const {
  useGetUserQuery,
  useGetTokenQuery,
  useGetUserTokenFBMutation,
  useGetUserTokenGoogleMutation,
  useGetUserProfileMutation,
} = userApi;
export default userApi;
