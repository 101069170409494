///api/v1/lg/game/user-subscribed/games
//"https://api.staging.imigames.io:443/api/v1/lg/subscription/deactivate",
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseUrl } from "../utils/ImiUtilData";

const subsListApi = createApi({
  reducerPath: "subsListApi",
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl,
  }),
  tagTypes: ["subs"],
  endpoints: (builder) => ({
    getSubsList: builder.query({
      query: (userToken) => ({
        url: "/lg/game/user-subscribed/games",
        method: "GET",
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      }),
      transformResponse: (response) => response?.data,
      providesTags: ["subs"],
    }),
    unSubGame: builder.mutation({
      query: ({ userToken, game_uuid }) => ({
        url: "/lg/subscription/deactivate",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
        body: {
          game_uuid: `${game_uuid}`,
        },
      }),
      transformResponse: (response) => response?.data,
      invalidatesTags: ["subs"],
    }),
  }),
});

export const { useGetSubsListQuery, useUnSubGameMutation } = subsListApi;
export default subsListApi;
