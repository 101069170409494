import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseUrl } from "../utils/ImiUtilData";

//https://api.staging.imigames.io/api/v1/lgo/catalog
//https://api.staging.imigames.io:443/api/v1/lgo/catalog/5f8ec3f24894ca0a95b64eb0?page=0&size=3
//https://api.staging.imigames.io:443/api/v1/lgo/catalog/hits?size=10
//{{host}}:{{api_gateway_port}}/api/v1/lgo/catalog/search?search=Sudoku&size=10

const catalogApi = createApi({
  reducerPath: "catalogApi",
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl,
  }),
  endpoints: (builder) => ({
    getCatalogList: builder.query({
      query: () => ({
        url: "/lgo/catalog",
        method: "GET",
        headers: {
          //"x-platform":"frimi"
        },
      }),
      transformResponse: (response) => response?.data,
    }),
    getItemList: builder.query({
      query: (data) => ({
        url: `/lgo/catalog/${data.id}?page=${data.page}&size=${data.size}`,
        method: "GET",
        headers: {
          //"x-platform":"frimi"
        },
      }),
      transformResponse: (response) => response?.data,
    }),
    getPopularList: builder.query({
      query: (data) => ({
        url: `/lgo/catalog/hits?size=${data.size}`,
        method: "GET",
        headers: {
          //"x-platform":"frimi"
        },
      }),
      transformResponse: (response) => response?.data,
    }),
    getSearchList: builder.query({
      query: (data) => ({
        url: `/lgo/catalog/search?search=${data.search}&size=${data.size}`,
        method: "GET",
        headers: {
          //"x-platform":"frimi"
        },
      }),
      transformResponse: (response) => response?.data,
    }),
  }),
});

export const {
  useGetCatalogListQuery,
  useGetItemListQuery,
  useGetPopularListQuery,
  useGetSearchListQuery,
} = catalogApi;
export default catalogApi;
