import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import { homeActions } from "../../store";
import { IMIThemeMode } from "../../utils/ImiUtilData";

const AppearanceDialog = (props) => {
  //console.log("apperancedialog");

  const { onClose, value: valueProp, open, ...other } = props;

  //const radioGroupRef = useRef(null);

  const dispatch = useDispatch();

  const handleEntering = () => {
    /*if (radioGroupRef.current != null) {
      radioGroupRef.current.focus();
    }*/
  };

  const handleCancel = () => {
    onClose();
  };

  const handleChange = (event) => {
    console.log(event.target.value);
    dispatch(homeActions.changeTheme(event.target.value));
  };

  //console.log(props.value);

  return (
    <Dialog
      maxWidth="xs"
      TransitionProps={{ onEnter: handleEntering }}
      open={open}
      scroll="paper"
      {...other}
    >
      <DialogTitle>Appearance</DialogTitle>
      <DialogContent dividers>
        <RadioGroup
          //ref={radioGroupRef}
          aria-label="theme"
          name="theme"
          value={props.value} //need to change
          onChange={handleChange}
        >
          <FormControlLabel
            value={IMIThemeMode.DARK}
            key="Dark"
            control={<Radio /*checked*/ />}
            label="Dark"
          />
          <FormControlLabel
            value={IMIThemeMode.LIGHT}
            key="Light"
            control={<Radio />}
            label="Light"
          />
        </RadioGroup>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleCancel} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AppearanceDialog;
