import { Grid, Paper } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import { getElementWidth } from "./ScreenManager";

const DummyBottomSpace = () => {
  const useStyles = makeStyles((theme) => ({
    paper: {
      height: getElementWidth(105), //DON'T CHANGE,DEPEND ON THE BOTTOM NAV HEIGHT
      textAlign: "center",
      //color: theme.palette.text.secondary,
      backgroundColor: theme.palette.background.default,
    },
  }));

  const classes = useStyles();
  return (
    <Grid container>
      <Grid item xs={12}>
        <Paper className={classes.paper} elevation={0} />
      </Grid>
    </Grid>
  );
};

export default DummyBottomSpace;
