import { Grid, Typography } from "@mui/material";
import React from "react";
import DummyBottomSpace from "../../utils/DummyBottomSpace";
import ImiSubItem from "./ImiSubItem";

const ImiSubsList = ({ data, onUnSub }) => {
  const getItems = () => {
    try {
      return data.map((item) => {
        return (
          <Grid item xs={12} key={item.id}>
            <ImiSubItem item={item} onUnSub={onUnSub} />
          </Grid>
        );
      });
    } catch (error) {
      return <Typography>Something went wrong....</Typography>;
    }
  };

  return (
    <Grid container spacing={1}>
      {getItems()}
      <DummyBottomSpace />
    </Grid>
  );
};

export default ImiSubsList;
