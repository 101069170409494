import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseUrl } from "../utils/ImiUtilData";

const subscriptionApi = createApi({
  reducerPath: "subscriptionApi",
  baseQuery: fetchBaseQuery({
    baseUrl: baseUrl,
  }),
  endpoints: (builder) => ({
    fetchTelcoList: builder.query({
      query: ({ userToken, gameUUID }) => ({
        url: `/lgo/subscription/${gameUUID}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
      }),
      transformResponse: (response) => response?.data,
    }),
    sendMSISDN: builder.mutation({
      query: ({ userToken, type, gameUUID, phoneNum }) => ({
        url: "/lg/subscription",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
        body: {
          type: type,
          game_uuid: gameUUID,
          msisdn: phoneNum,
        },
      }),
      transformResponse: (response) => response?.data,
    }),
    sendOTP: builder.mutation({
      query: ({ userToken, type, gameUUID, phoneNum, otp }) => ({
        url: "/lg/subscription/confirm",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userToken}`,
        },
        body: {
          type: type,
          game_uuid: gameUUID,
          msisdn: phoneNum,
          additional_info: otp,
        },
      }),
      transformResponse: (response) => response?.data,
    }),
  }),
});

export const {
  useFetchTelcoListQuery,
  useSendMSISDNMutation,
  useSendOTPMutation,
} = subscriptionApi;
export default subscriptionApi;
